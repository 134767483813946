<template>
<div>
  <h1>aaaaaaaa</h1>
  <Chart />
</div>
</template>

<script>
// @ is an alias to /src
import Chart from '@/components/chart.vue'

export default {
  name: 'Home',
  components: {
    Chart
  }
}
</script>
